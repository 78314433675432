<template lang="pug">
  .wrapper
    h1.welcome_message {{ $t('base.hello', { name: user.user.full_name }) }}
    .items-container(
      v-if="showFilterByCompany"
    )
      h2.title_block {{ 'base.select_company' | translate }}
        .company-items
          company-item(
            v-for="company in companies"
            :key="company.id"
            :item="company"
            :activeCompanies="getActiveCompanies"
            @selectedCompany="selectedCompany"
            @deselectCompany="deselectCompany"
          )
    .items-container
      h2.title_block {{ 'base.select_farm' | translate }}
      template(v-if="userHasFarms")
        .farm-items
          farm-item(
            v-for="(farm, index) in filteredFarms"
            :key="`${farm.id}` + `${index}`"
            :item="farm"
          )
      template(v-else)
        p.no_farms_text {{ 'base.user_has_not_farms' | translate }}
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import CompanyItem from './CompanyItem.vue'
import FarmItem from './FarmItem.vue'
import roles from '@/util/roles'

export default {
  name: 'Main',
  components: {
    CompanyItem,
    FarmItem
  },
  data: () => ({
    activeCompanies: []
  }),
  mounted () {
    this.setCurrentFarm(null)
    this.fetchUserFarms()
    this.fetchCustomerCompanies()
  },
  computed: {
    ...mapGetters(['user', 'farms', 'companies']),
    filteredFarms () {
      if (this.activeCompanies.length > 0) {
        return this.farms.filter(farm => !this.activeCompanies.includes(farm.company_id))
      }
      return this.farms
    },
    showFilterByCompany () {
      return this.user.user.role === roles.admin || this.user.user.role === roles.customer
    },
    getActiveCompanies () {
      return this.activeCompanies
    },
    userHasFarms () {
      return this.user.user.farm_count > 0 || this.user.user.role === roles.admin
    }
  },
  methods: {
    ...mapActions(['setCurrentFarm', 'fetchUserFarms', 'fetchCustomerCompanies']),
    selectedCompany (company) {
      this.activeCompanies.push(company)
    },
    deselectCompany (company) {
      this.activeCompanies = this.activeCompanies.filter(id => id !== company)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  padding-top: 4em
}
.welcome_message {
  width: 100%;
  text-align: center;
  color: #1DA643;
  font-family: 'Museo Sans', serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}

.title_block {
  width: 100%;
  text-align: center;
  font-family: 'Museo Sans', serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  margin: 20px 0;
}

.no_farms_text {
  width: 100%;
  text-align: center;
  font-family: 'Museo Sans', serif;
  font-size: 16px;
  margin: 40px 0;
}

.farm-items,
.company-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.company-items {
  justify-content: center;
}
</style>
