<template lang="pug">
  .farm-block(
    @click="selectFarm"
  )
    div
      .farm-company(:title="item.company_name") {{ formattedName }}
      .farm-name {{ item.name }}
    v-icon(
      icon="chevron-right"
      color="#1DA643"
      font-size=1.2
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getRouteRedirect } from '@/util/utils'

export default {
  name: 'FarmItem',
  props: {
    item: Object
  },
  computed: {
    ...mapGetters(['user']),
    formattedName () {
      return this.item.company_name.length > 19 ? this.item.company_name.substring(0, 19) + '...' : this.item.company_name
    }
  },
  methods: {
    ...mapActions(['setCurrentFarm']),
    selectFarm () {
      this.setCurrentFarm(this.item.id)
      this.$router.push(getRouteRedirect(this.user.user.role, '/farm'))
    }
  }
}
</script>

<style scoped lang="scss">
.farm-block {
  display: flex;
  flex: 0 0 250px;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: #fff;
  border-radius: 10px;
  margin: 15px 15px 0 0;
  padding-right: 20px;
}
.farm-block:hover {
  cursor:pointer;
}
.farm-name {
  position: relative;
  font-family: "Museo Sans", serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  padding-left: 20px;
}

.farm-name::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 4px;
  background-color: #1DA643;
}

.farm-company {
  position: relative;
  font-family: "Museo Sans", serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  padding-left: 20px;
  padding-bottom: 4px;
}

.farm-company::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 4px;
  background-color: #1DA643;
}
</style>
