<template lang="pug">
  .company-block(
    @click="selectedCompany"
    :class="{ 'active': isActive }"
  )
    .company-name
      p {{ item.name }}
      p.farms_count {{ $t('base.farms_count', { count: item.farms.length}, item.farms.length) }}
    v-icon(
     icon="chevron-circle-down"
     :color="iconColor"
     font-size=1
    )

</template>

<script>
export default {
  name: 'CompanyItem',
  props: {
    item: Object,
    activeCompanies: Array
  },
  computed: {
    isActive () {
      return this.activeCompanies.includes(this.item.id)
    },
    iconColor () {
      return this.isActive ? '#CCD2E3' : '#1DA643'
    }
  },
  methods: {
    selectedCompany () {
      if (this.isActive) {
        this.$emit('deselectCompany', this.item.id)
      } else {
        this.$emit('selectedCompany', this.item.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.company-block {
  display: flex;
  flex: 0 0 250px;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  margin: 15px 15px 0;
  padding-right: 20px;
  border: 1px solid #1DA643;

  &.active {
    border-color: #CCD2E3;
    .company-name {
      border-left-color: #CCD2E3;
    }
  }
}
.company-block:hover {
  cursor:pointer;
}
.company-name {
  font-family: "Museo Sans", serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  padding-left: 20px;
  border-left: 4px solid #1DA643;

  .farms_count {
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
  }
}
.company-name p {
  margin: 5px 0;
  text-align: left;
}
</style>
